import axios from 'axios/index'
import moment from "moment";
import { showMessage } from 'store/actions/fuse'
import config from 'config'

export const GET_PROMOTION = '[E-COMMERCE APP] GET PROMOTION'
export const SAVE_PROMOTION = '[E-COMMERCE APP] SAVE PROMOTION'
export const DELETE_PROMOTION = '[E-COMMERCE APP] DELETE PROMOTION'
export const GET_BUSSINES_TYPE = '[INVENTORY] GET BUSSINES TYPE'

export function getPromotion(promoId) {
  const request = axios.get(`${config.endpoints.bff}/v1/promotions/${promoId}`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_PROMOTION,
          payload: response.data
        })
      )
}

export function deletePromotion(id) {
  const request = axios.delete(`${config.endpoints.bff}/v1/promotions/${id}`)

  return (dispatch) => {
    return request
      .then((response) =>
        dispatch({
          type: DELETE_PROMOTION,
          payload: response.data
        })
      )
  }
}

export function savePromotion(data) {
  const promo = data
  return (dispatch) => {
    let request = uploadImage(data.promoImage)
    delete promo.promoImage
    return request.then(async (image) => {
      let response
      promo.image = image && image.data
      if (data._id) {
        response = await axios.put(`${config.endpoints.bff}/v1/promotions/${data._id}`, promo)
        dispatch(showMessage({ message: 'Promoción actualizado' }))
      } else {
        if (promo.provider === true){
          promo.active = true
        }
        response = await axios.post(`${config.endpoints.bff}/v1/promotions`, promo)
        dispatch(showMessage({ message: 'Promoción creada' }))
      }

      return dispatch({
        type: SAVE_PROMOTION,
        payload: Object.assign(promo, response.data)
      })
    }
    )
  }
}

export function getBussinesType () {
  const request = axios.get(`${config.endpoints.bff}/v1/bussinesType`)
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_BUSSINES_TYPE,
          payload: response.data
        })
      )
}

async function uploadImage(file) {
  if (file) {
    const form = new FormData()
    form.append('file', file)
    return axios.post(`${config.endpoints.bff}/v1/promotions/image`, form)
  } else {
    return {}
  }
}

export function newPromotion() {
  const today = moment(new Date()).format('YYYY-MM-DDTHH:mm')

  const data = {
    type: 'Promotion',
    name: null,
    image: null,
    price: 0,
    description: '',
    startDate: today,
    expirationDate: today,
    active: true,
    group: 0,
    bussinesTypes: []
  }

  return {
    type: GET_PROMOTION,
    payload: data
  }
}
